import React, { useEffect, useState } from 'react'
import HeaderInner from '../component/HeaderInner';
import Header from '../component/Header';
import { get } from '../helpers/helper_api';
import Loader from '../component/Loader';

export default function Terms() {
    useEffect(() => {

        window.scrollTo(0, 0);
    }, [])

    const [terms, setTerms] = useState(undefined)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getData1()
    }, [])


    const getData1 = () => {
        setLoading(true)
        get(`setting`)
            .then((res) => {
                setLoading(false)
                if (res?.statusCode == 200) {
                    setTerms(res?.data)
                }

            }).catch(err => {
                console.log('errer', err)
                setLoading(false)
            })
    }


    return (
        <>
            {
                loading ? <Loader /> : null
            }
            <Header />
            <div className='mt-100px mt_0_mobile'>
                <div class="contact-form-style-one" style={{ boxShadow: 'none' }}>
                    <div class="row">
                        <div className='conditions_modal'>
                            <h2 class="title mb-5">Terms & Conditions</h2>
                            <div className='terms_content' dangerouslySetInnerHTML={{ __html: terms?.terms }} />



                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
