
import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { get } from '../helpers/helper_api';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import HeaderInner from '../component/HeaderInner';
import moment from 'moment-timezone';

export default function MyWallet() {
    const [data, setData] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState(undefined)
    const [confirmModal, setConfirmModal] = useState(false);

    const toggle = () => setConfirmModal(!confirmModal);
    useEffect(() => {

        window.scrollTo(0, 0);
    }, [])
    const user = useSelector((state) => state.user.data);
    useEffect(() => {
        if (user?._id) {
            getData(user?._id)
        }
    }, [user])

    const getData = (userId) => {
        setLoading(true)
        get(`user/detail?userId=${userId}`)
            .then((res) => {
                setLoading(false)
                if (res?.statusCode == 200) {
                    setData(res?.data)
                }
                // console.log('user res', res)

            }).catch(err => {
                setLoading(false)
                console.log('errer', err)
            })
    }


    // console.log('my profile',user)
    return (
        <div className='mt-5 mt_0_mobile'>
            <HeaderInner />
            <Modal centered isOpen={confirmModal} >
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    <div className='confirm-desc'>
                        <img src={image ? image : null} />
                    </div>
                </ModalBody>

            </Modal>
            <div class="contact-form-style-one" style={{ boxShadow: 'none' }}>



                <div className='profile-section'>
                    {/* <div className='sort-detail'> */}
                        {/* <div className='userIcon'>
                           {
                            data?.name?.split(' ')[]
                           }
                        </div> */}
                        {/* <div className='user-detail-box'>
                            <h4><strong>Name</strong>{data?.name}</h4>
                            <h6><strong>Cust id</strong>{data?.userId}</h6>
                            <h6><strong>Email</strong>{data?.email}</h6>
                            <p><strong>Mobile</strong>{data?.mobile}</p>
                        </div> */}
                         <h2 class="title mb-5">My wallet</h2>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='user-card-box-1'>
                                    <div className='box-content'>
                                        <p>Power Money</p>
                                        <span>₹536456</span>
                                    </div>
                                    <div className='box-content'>
                                        <p>Power Incentive</p>
                                        <span>₹5346456</span>
                                    </div>
                                    <div className='box-content'>
                                        <p>Total </p>
                                        <span>₹5346456</span>
                                    </div>
                                    <div className='box-content mt-2'>
                                       <button>Withdraw</button>
                                    </div>

                                </div>
                            </div>
                           

                        </div>
                    {/* </div> */}
                    
                </div>
            </div>
        </div>
    )
}
