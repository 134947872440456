import { AvCheckbox, AvCheckboxGroup, AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../component/Header'
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

export default function Faq() {


    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    useEffect(() => {

      window.scrollTo(0, 0);
  }, [])

    return (
        <div>
            <Header />
           
            <div class="contact-form-style-one contact-form-style-one-section mt-100px mt_0_mobile" style={{ boxShadow: 'none' }}>
                <h2 class="title mb-5">FAQ's</h2>
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem>
                        <AccordionHeader targetId="1">What is Apex-Leap?</AccordionHeader>
                        <AccordionBody accordionId="1">
                            <p>Apex-Leap is a mobile app that allows you to earn money by scanning and uploading book pages. We provide the book material, and once your scans are reviewed and approved, you’ll receive payment.</p>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="2">How do I get started with Apex-Leap?</AccordionHeader>
                        <AccordionBody accordionId="2">
                          <p><strong>Download the App:</strong> Install Apex-Leap from the App Store or Google Play Store.
                          Sign Up: Create your account using email.</p>
                          <p><strong>Receive Book Material: </strong>Access the book content provided in the app.</p>
                          <p><strong>Start Scanning:</strong> Scan the book pages using the app and upload them.</p>

                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="3">What kind of books will I be scanning?</AccordionHeader>
                        <AccordionBody accordionId="3">
                          <p>You will be assigned specific books to scan based on availability and our needs.</p>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="4">How do I upload the scanned pages?</AccordionHeader>
                        <AccordionBody accordionId="4">
                          <p>After scanning, upload the pages directly through the Apex-Leap app. Follow the in-app instructions for submission.</p>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="5">How will I know if my uploads are approved?</AccordionHeader>
                        <AccordionBody accordionId="5">
                          <p>You’ll receive a notification in the app once your uploads have been reviewed. Approved scans will be reflected in your account status.</p>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="6">How and when do I get paid?</AccordionHeader>
                        <AccordionBody accordionId="6">
                          <p><strong>Funds Transfer:</strong> Payments are first added to your Apex-Leap app wallet.</p>
                          <p><strong>Request Transfer:</strong> You can request to transfer funds from your app wallet to your bank account or preferred payment method.</p>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="7">Are there any fees associated with transferring funds?</AccordionHeader>
                        <AccordionBody accordionId="7">
                          <p>Please check the app’s T&C section for details on any fees or transfer limits that may apply.</p>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="8">How can I ensure my scans meet the quality standards?</AccordionHeader>
                        <AccordionBody accordionId="8">
                          <p><strong>Good Lighting:</strong> Ensure pages are well-lit and visible.</p>
                          <p><strong>Hold Steady:</strong> Keep your device steady to avoid blurs.</p>
                          <p><strong>Proper Alignment:</strong> Make sure pages are aligned within the scan frame.</p>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="9">What should I do if I encounter issues with scanning or uploading?</AccordionHeader>
                        <AccordionBody accordionId="9">
                          <p><strong>Contact Support:</strong> Reach out to our support team through the app or email info@apex-leap.com for further assistance.</p>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="10">Are there any restrictions on who can participate?</AccordionHeader>
                        <AccordionBody accordionId="10">
                          <p>Participants must be over 18 and comply with the app’s terms and conditions. Please review the eligibility requirements during sign-up.</p>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="11">Where can I find more information or updates?</AccordionHeader>
                        <AccordionBody accordionId="11">
                          <p>Check the app’s announcements section or visit our website for the latest updates and additional information.</p>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="12">How can I contact Apex-Leap support?</AccordionHeader>
                        <AccordionBody accordionId="12">
                          <p>Contact our support team via the in-app support feature or email us at info@apex-leap.com Or Call +91 8000505059 </p>
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    )
}

