import React, { useEffect, useState } from 'react'
import { Link, Element, Events, animateScroll as scroll, scroller } from 'react-scroll';
import { Link as Alink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../store/user';
export default function HeaderInner() {
    const navigator = useNavigate();
    const [toggleMenu, setToggleMenu] = useState(false)
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.data);
    useEffect(() => {
        getUser();
    }, []);

    const getUser = () => {
        let user = localStorage.getItem("user");
        if (user) {
            dispatch(setUser(JSON.parse(user)));
        }
    }


    useEffect(() => {
        if (window.innerWidth < 991) {
            const navbarMenu = document.getElementById('mobile_menu');
            navbarMenu.classList.add('collapse-mobile');
        }
    }, [])


    const menuCloseFunction = () => {
        const navbarMenu = document.getElementById('mobile_menu');
        navbarMenu.classList.remove('collapse-mobile');
    };
    const logout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("step");
        let data = localStorage.removeItem("form");
        let data1 = localStorage.removeItem("form1");
        let data2 = localStorage.removeItem("form2");
        let data3 = localStorage.removeItem("form3");
        dispatch(setUser(null));
        navigator('/')
    }

    return (
        <header>

            <nav class="navbar mobile-sidenav navbar-sticky navbar-default validnavs navbar-fixed dark no-background">
                <div class="container-fill d-flex justify-content-between align-items-center">

                    <div class="navbar-header">
                        <button type="button" class="navbar-toggle" data-toggle="collapse" onClick={() => setToggleMenu(true)}>
                            <i class="fa fa-bars"></i>
                        </button>
                        <Alink class="navbar-brand" to="/">
                            <img src={require('../assets/img/logo.png')} class="logo" alt="Logo" />
                        </Alink>
                    </div>

                    <div class={toggleMenu ? 'collapse navbar-collapse collapse-mobile show' : 'collapse navbar-collapse'} id='mobile_menu'>
                        <img src={require('../assets/img/logo.png')} alt="Logo" />
                        <button type="button" class="navbar-toggle" data-toggle="collapse" onClick={() => setToggleMenu(false)}>
                            <i class="fa fa-times"></i>
                        </button>
                        <ul class="nav navbar-nav navbar-center" data-in="fadeInDown" data-out="fadeOutUp">
                            <li ><a href='https://apex-leap.com' spy={true} offset={-50} smooth={true} duration={200}>Home</a></li>
                            {/* <li ><a spy={true} offset={-50} smooth={true} duration={200}>Assign Work</a></li>
                            <li onClick={menuCloseFunction}><Alink to="/my-wallet" spy={true} offset={-50} smooth={true} duration={200}>My Wallet</Alink></li> */}
                            {
                                user ?
                                    <li onClick={menuCloseFunction}><Alink to="/my-profile" spy={true} offset={-50} smooth={true} duration={200}>My Profile</Alink></li>
                                    :
                                    null
                            }

                            <li onClick={menuCloseFunction}><Alink to="/faq" spy={true} offset={-50} smooth={true} duration={200}>FAQ's</Alink></li>
                            <li><Alink to="/terms">Terms</Alink></li>
                            <li onClick={menuCloseFunction}><a href="https://apex-leap.com/#contact" spy={true} offset={-50} smooth={true} duration={200}>contact</a></li>

                            {
                                user ?
                                    <li className='mobile-link'><a href="javascript:void(0)" onClick={logout} spy={true} offset={-50} smooth={true} duration={200}>Logout</a></li>
                                    :
                                    <li className='mobile-link'><Alink to="/login" spy={true} offset={-50} smooth={true} duration={200}>Login</Alink></li>
                            }

                            {
                                user ?
                                    <p className='mb-0 mobile-link user_header-text'>Dear {user?.name}</p>
                                    :
                                    <li className='mobile-link'><Alink to="/register" spy={true} offset={-50} smooth={true} duration={200}>Register</Alink></li>

                            }

                        </ul>
                    </div>

                    <div class="attr-right">

                        <div class="attr-nav">
                            {

                                <li class="button">
                                    {
                                        user ? <p className='mb-0 user_header-text'>Hello {user?.name}</p> : <Alink to="/register">Register</Alink>
                                    }
                                    {
                                        user ? <a href="javascript:void(0)" className='logout-btn' onClick={logout} >Logout</a> : <Alink to="/login">Login</Alink>
                                    }

                                </li>
                            }
                            <ul>

                            </ul>
                        </div>

                        <div class="overlay-screen"></div>

                    </div>

                </div>
            </nav>

        </header>
    )
}
