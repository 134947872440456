import { AvCheckbox, AvCheckboxGroup, AvField, AvForm, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { get, post, uploadS3 } from '../helpers/helper_api'
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../component/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../store/user';
import moment from 'moment-timezone';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import HeaderInner from '../component/HeaderInner';
export default function Registor() {
    const navigator = useNavigate();
    const [step, setStep] = useState(1)
    const [email, setEmail] = useState(undefined)
    const [userId, setUserId] = useState(undefined)
    const [docTp, setDocTp] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [check, setCheck] = useState(false)
    const [isPay, setIsPay] = useState('IMPS')
    const [plan, setPlan] = useState(undefined)
    const [choosePlan, setChoosePlan] = useState(undefined)
    const [amount, setAmount] = useState(undefined)
    const [amountText, setAmountText] = useState(undefined)
    const user = useSelector((state) => state.user.data);
    const [file, setFile] = useState(undefined)
    const [docFile, setDocFile] = useState(undefined)
    const [panFile, setPanFile] = useState(undefined)
    const [terms, setTerms] = useState(undefined)
    const [formData, setFormData] = useState(undefined)
    const [formData1, setFormData1] = useState(undefined)
    const [formData2, setFormData2] = useState(undefined)
    const [formData3, setFormData3] = useState(undefined)
    const [pinCode, setPinCode] = useState(undefined)
    const [state, setState] = useState(undefined)
    const [city, setCity] = useState(undefined)
    const [confirmModal, setConfirmModal] = useState(false);
    const [payAmount, setPayAmount] = useState(false);
    const [isAgree, setIsAgree] = useState(false);
    const [useId, setUseId] = useState();
    const [panNumber, setPanNumber] = useState();
    const [adharValue, setAdharValue] = useState('');
    const [timeLeft, setTimeLeft] = useState(0);
    const toggle = () => setConfirmModal(!confirmModal);

    const dispatch = useDispatch()

    // console.log('user', user)
    useEffect(() => {

        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (localStorage.getItem('step')) {
            setStep(localStorage.getItem('step'))
        }
    }, [localStorage.getItem('step')])
    useEffect(() => {
        if (localStorage.getItem("form") || localStorage.getItem("form1") || localStorage.getItem("form2") || localStorage.getItem("form3")) {
            let data = localStorage.getItem("form");
            let data1 = localStorage.getItem("form1");
            let data2 = localStorage.getItem("form2");
            let data3 = localStorage.getItem("form3");
            setFormData(JSON.parse(data))
            setFormData1(JSON.parse(data1))
            setFormData2(JSON.parse(data2))
            setFormData3(JSON.parse(data3))
            setDocTp(JSON.parse(data2)?.docType)

        }

    }, [localStorage.getItem("form"), localStorage.getItem("form1"), localStorage.getItem("form2"), localStorage.getItem("form3")])
    useEffect(() => {
        if (localStorage.getItem('userId')) {
            setUserId(localStorage.getItem('userId'))
        }
    }, [localStorage.getItem('userId')])


    useEffect(() => {
        if (pinCode?.length == 6) {
            getPinCode()
        }
    }, [pinCode?.length == 6]);

    const getPinCode = () => {
        let body = {
            pincode: pinCode
        }
        post(`pincode`, body)
            .then((res) => {
                if (res?.statusCode == 200) {
                    setState(res?.data?.stateName)
                    setCity(res?.data?.districtName)
                }
            }).catch(err => {
                console.log('errer', err)
            })
    }


    console.log('pinCode', pinCode)

    useEffect(() => {
        if (choosePlan) {
            let price = (choosePlan?.amount + choosePlan?.hCharge) + (choosePlan?.amount * choosePlan?.gst / 100);
            setAmount(price);
            setAmountText(numberToText(price))
        }
    }, [choosePlan])


    useEffect(() => {
        if (user)
            setUseId(user?._id)
    }, [user])

    useEffect(() => {
        getPlan()
        getData1()
    }, [])


    useEffect(() => {
        if (timeLeft === 0) return; // Exit if time is up

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    // Format timeLeft as minutes:seconds
    const formatTime = (time) => {
        return `${time < 10 ? `0${time}` : time}`;
    };



    const getData = (userId) => {
        setLoading(true)
        get(`user/detail?userId=${useId}`)
            .then((res) => {
                setLoading(false)
                localStorage.setItem("user", JSON.stringify(res?.data));
                dispatch(setUser(res?.data));
                // console.log('user res', res)

            }).catch(err => {
                setLoading(false)
                console.log('errer', err)
            })
    }
    const getData1 = () => {
        get(`setting`)
            .then((res) => {
                if (res?.statusCode == 200) {
                    setTerms(res?.data)
                }

            }).catch(err => {
                console.log('errer', err)
            })
    }

    const getPlan = () => {

        get('plan')
            .then((res) => {
                // console.log('plan res', res)
                if (res?.statusCode == 200) {
                    setPlan(res?.data)
                    setChoosePlan(res?.data[0])
                }


            }).catch(err => {

                console.log('errer', err)
            })
    }

    const handleValidSubmit = (e, v) => {
        setLoading(true)
        let body = {
            ...v,
            deviceType: 'web',
            deviceToken: 'abc'
        }
        localStorage.setItem("form", JSON.stringify(body));
        setFormData(body)

        post('register', body)
            .then((res) => {
                // console.log('res', res);
                setLoading(false)
                if (res?.statusCode == 200) {
                    toast.success(res?.message)
                    localStorage.setItem("user", JSON.stringify(res?.data));
                    dispatch(setUser(res?.data));
                    setUserId(res?.data?.userId)
                    setStep(2)
                    localStorage.setItem('step', 2)
                    localStorage.setItem('userId', res?.data?.userId)
                    window.scrollTo(0, 0);
                    setTimeLeft(60)
                }
                if (res?.statusCode == 208) {
                    toast.error(res?.message)
                }
                if (res?.statusCode == 404) {
                    toast.error(res?.error)
                }
            }).catch(err => {
                setLoading(false)
                console.log('errr', err)
            })


    }
    const handleValidSubmitResend = (e, v) => {
        setLoading(true)
        let body = {
            email: formData?.email,
            name: formData?.name,
            dob: formData?.dob,
            mobile: formData?.mobile,
            deviceType: 'web',
            deviceToken: 'abc'
        }
        localStorage.setItem("form", JSON.stringify(body));
        setFormData(body)

        post('register', body)
            .then((res) => {
                // console.log('res', res);
                setLoading(false)
                if (res?.statusCode == 200) {
                    toast.success(res?.message)
                    localStorage.setItem("user", JSON.stringify(res?.data));
                    dispatch(setUser(res?.data));
                    setUserId(res?.data?.userId)
                    setStep(2)
                    localStorage.setItem('step', 2)
                    localStorage.setItem('userId', res?.data?.userId)
                    window.scrollTo(0, 0);
                    setTimeLeft(60)
                }
                if (res?.statusCode == 208) {
                    toast.error(res?.message)
                }
                if (res?.statusCode == 404) {
                    toast.error(res?.error)
                }
            }).catch(err => {
                setLoading(false)
                console.log('errr', err)
            })


    }
    const handleValidSubmitVerify = (e, v) => {
        setLoading(true)
        let body = {
            otp: v?.otp,
            password: v?.password,
            userId
        }
        localStorage.setItem("form1", JSON.stringify(body));
        setFormData1(body)
        if (v?.password === v?.repassword) {
            post('verify_register', body)
                .then((res) => {
                    // console.log('res', res);
                    setLoading(false)
                    if (res?.statusCode == 200) {
                        toast.success(res?.message)
                        setStep(3)
                        localStorage.setItem("user", JSON.stringify(res?.data));
                        dispatch(setUser(res?.data));
                        localStorage.setItem('step', 3)
                        window.scrollTo(0, 0);
                    }
                    if (res?.statusCode == 400) {
                        toast.error(res?.message)
                    }
                    if (res?.statusCode == 404) {
                        toast.error(res?.error)
                    }
                }).catch(err => {
                    setLoading(false)
                    console.log('errr', err)
                })
        } else {
            toast.error('Please Match passward')
            setLoading(false)
        }


    }

    const handleValidSubmitKyc = async (e, v) => {
        setLoading(true)
        let body = {
            ...v,
            userId,
            namek: undefined,
            dobk: undefined,
            emailk: null,
            panNumber1: undefined,
            docNumber1: undefined,
            mobilek: undefined,

        }
        // console.log('body',body)
        // return
        if (docFile) {
            if (docFile.type == 'image/jpeg' || docFile.type == 'image/jpg' || docFile.type == 'image/png') {
                const recept = await uploadS3("paymentReceipt", docFile, new Date().getTime() + '.wepb');
                if (recept?.statusCode == 200)
                    body.docImage = recept?.data
            }
        }
        if (panFile) {
            if (panFile.type == 'image/jpeg' || panFile.type == 'image/jpg' || panFile.type == 'image/png') {
                const recept = await uploadS3("paymentReceipt", panFile, new Date().getTime() + '.wepb');
                if (recept?.statusCode == 200)
                    body.panImage = recept?.data
            }
        }
        localStorage.setItem("form2", JSON.stringify(body));
        setFormData2(body)
        post('kyc', body)
            .then((res) => {
                // console.log('res', res);
                setLoading(false)
                if (res?.statusCode == 200) {
                    toast.success(res?.message)
                    setUserId(res?.data?.userId)
                    setStep(4)
                    localStorage.setItem('step', 4)
                    window.scrollTo(0, 0);
                }
                if (res?.statusCode == 208) {
                    toast.error(res?.message)
                }
                if (res?.statusCode == 404) {
                    toast.error(res?.error)
                }
            }).catch(err => {
                setLoading(false)
                console.log('errr', err)
            })
    }
    const handleValidSubmitBank = (e, v) => {
        setLoading(true)
        let body = {
            ...v,
            userId,
            reacNumber: undefined
        }
        localStorage.setItem("form3", JSON.stringify(body));
        setFormData3(body)
        if (v?.acNumber === v?.reacNumber) {
            post('bank', body)
                .then((res) => {
                    // console.log('res', res);
                    setLoading(false)
                    if (res?.statusCode == 200) {
                        toast.success(res?.message)

                        setStep(5)
                        localStorage.setItem('step', 5)
                        window.scrollTo(0, 0);
                    }
                    if (res?.statusCode == 208) {
                        toast.error(res?.message)
                    }
                    if (res?.statusCode == 404) {
                        toast.error(res?.error)
                    }
                }).catch(err => {
                    setLoading(false)
                    console.log('errr', err)
                })
        } else {
            setLoading(false)
            toast.error('Please Match Account Number!')
        }

    }

    const handleValidSubmitPayImps = async (e, v) => {
        setLoading(true)
        let body = {
            ...v,
            file: undefined,
            type: isPay == 'IMPS' ? 'bank' : 'upi',
            token: user?.token,
            gst: choosePlan?.gst,
            title: choosePlan?.title,
            avFieldCheckbox: undefined,
            amount: choosePlan?.amount,
            bal: undefined
        }

        if (file) {
            if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
                const recept = await uploadS3("paymentReceipt", file, new Date().getTime() + '.wepb');
                if (recept?.statusCode == 200)
                    body.receipt = recept?.data
            }
        }
        if (payAmount >= 5000) {
            post('transaction', body)
                .then((res) => {
                    // console.log('res', res);
                    setLoading(false)
                    if (res?.statusCode == 200) {
                        toast.success(res?.message)
                        setConfirmModal(true)
                        getData()
                        localStorage.removeItem("form");
                        localStorage.removeItem("form1");
                        localStorage.removeItem("form2");
                        localStorage.removeItem("form3");
                        localStorage.removeItem("step");
                        window.scrollTo(0, 0);
                    }
                    if (res?.statusCode == 208) {
                        toast.error(res?.message)
                    }
                    if (res?.statusCode == 404) {
                        toast.error(res?.error)
                    }
                }).catch(err => {
                    setLoading(false)
                    console.log('errr', err)
                })
        } else {
            toast.error("Amount can't be less than minimum payable amount.");
            setLoading(false)
        }


    }


    console.log('payAmount', payAmount)

    const handleValidSubmitPay = async (e, v) => {
        setLoading(true)
        let body = {
            ...v,
            file: undefined,
            type: isPay == 'IMPS' ? 'bank' : 'upi',
            token: user?.token,
            gst: choosePlan?.gst,
            title: choosePlan?.title,
            avFieldCheckbox: undefined,
            amount: choosePlan?.amount,
            upiNumber: Number(v?.upiNumber),
            bal: undefined
        }

        if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
            const recept = await uploadS3("paymentReceipt", file, new Date().getTime() + '.wepb');
            if (recept?.statusCode == 200)
                body.receipt = recept?.data
        }
        if (payAmount >= 5000) {
            post('transaction', body)
                .then((res) => {
                    // console.log('res', res);
                    setLoading(false)
                    if (res?.statusCode == 200) {
                        toast.success(res?.message)
                        setConfirmModal(true)
                        getData()
                        localStorage.removeItem("form");
                        localStorage.removeItem("form1");
                        localStorage.removeItem("form2");
                        localStorage.removeItem("form3");
                        localStorage.removeItem("step");
                        window.scrollTo(0, 0);
                    }
                    if (res?.statusCode == 208) {
                        toast.error(res?.message)
                    }
                    if (res?.statusCode == 404) {
                        toast.error(res?.error)
                    }
                }).catch(err => {
                    setLoading(false)
                    console.log('errr', err)
                })
        } else {
            toast.error('Amount sould be Greater than Your Payable Amount');
            setLoading(false)
        }

    }

    const digitToWord = {
        0: 'zero',
        1: 'one',
        2: 'two',
        3: 'three',
        4: 'four',
        5: 'five',
        6: 'six',
        7: 'seven',
        8: 'eight',
        9: 'nine'
    };


    function numberToText(number) {
        // Convert the number to a string
        const numberStr = number.toString();

        // Split the string into individual digits and map them to words
        const words = numberStr.split('').map(digit => digitToWord[digit]);

        // Join the words with a space in between
        return words.join(' ');
    }


    const payAmountFun = (e) => {
        setPayAmount(e.target.value)
        if (e.target.value > amount) {
            toast.error('Amount sould be less than Payable Amount')
        }
        // if (e.target.value < 5000) {
        //     toast.error('Amount sould be Greater than Your Payable Amount')
        // }
    }

    const handleChange = (e) => {
        const inputValue = e.target.value.replace(/\s+/g, ''); // Remove existing spaces
        const formattedValue = inputValue.replace(/(\d{4})(?=\d)/g, '$1 '); // Add space after every 4 digits
        setAdharValue(formattedValue);
    };

    console.log('terms', terms)

    return (
        <>
            <HeaderInner />
            <div className='mt-100px mt_0_mobile'>
                {
                    loading ? <Loader /> : null
                }


                <Modal centered isOpen={confirmModal} >
                    <ModalBody>
                        <div className='confirm-desc'>
                            <h3>Deposit request created successfully</h3>
                            <p>*** Kindly wait for maximum 12 hours for confirmation of deposit. Once it confirmed you will get a confirmation message on your email.</p>
                            <button onClick={() => { setConfirmModal(false); navigator('/my-profile') }}>My Account</button>
                        </div>
                    </ModalBody>

                </Modal>

                {
                    step == 1 ?

                        <div class="contact-form-style-one" style={{ boxShadow: 'none' }}>

                            <div className='header-title'>
                                <h4 className=''>Join Apex Leap Today</h4>
                                <h2 class="title">Unlock Your Earning Potential <br />with Simple Steps</h2>

                            </div>
                            <AvForm
                                onValidSubmit={handleValidSubmit}
                                class="contact-form contact-form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <AvField name="name" value={formData?.name} label="Name" required />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <AvField name="dob" value={formData?.dob} label="DOB" type="date" required />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <AvField name="mobile" value={formData?.mobile} label="Mobile Number" required />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <AvField name="email" type="email" value={formData?.email} onClick={(e) => setEmail(e.target.value)} label="Email Id" required />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12 button_box_col">
                                        <button type="submit">
                                            Next
                                        </button>
                                    </div>
                                </div>

                            </AvForm>
                        </div>
                        :
                        null
                }
                {
                    step == 2 ? <div class="contact-form-style-one" style={{ boxShadow: 'none' }}>

                        <h2 class="title mb-5">Welcome to Apex Leap Team</h2>
                        <AvForm
                            onValidSubmit={handleValidSubmitVerify}
                            class="contact-form contact-form">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <AvField name="custId" disabled value={userId} label="Cust Id" required />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <AvField name="password" value={formData1?.password} label="Password" required />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <AvField name="repassword" value={formData1?.repassword} label="Re Password" required />
                                    </div>
                                </div>
                                <div class="col-lg-8 otp_box_">
                                    <div class="form-group">
                                        <AvField name="otp" value={formData1?.otp} label="OTP" required />
                                        <span>Please check your email for a OTP.</span>
                                    </div>
                                    <div className='otp-box-btn'>

                                        {timeLeft === 0 ? <button type='button' onClick={handleValidSubmitResend}>Resend OTP</button> : <span>OTP Expires In: {formatTime(timeLeft)} sec </span>}
                                    </div>

                                </div>

                            </div>

                            <div class="row">
                                <div class="col-lg-12 button_box_col">
                                    <button type="button" className='gary-bg' onClick={() => setStep(1)}>
                                        Previous
                                    </button>
                                    <button type="submit" className='mx-3'>
                                        Next
                                    </button>
                                </div>
                            </div>

                        </AvForm>
                    </div>
                        :
                        null
                }
                {
                    step == 3 ?

                        <div class="contact-form-style-one" style={{ boxShadow: 'none' }}>
                            <AvForm
                                onValidSubmit={handleValidSubmitKyc}
                                class="contact-form contact-form">
                                <h2 class="title mb-5">Complete Your KYC</h2>
                                <div class="row">

                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <AvField name="namek" label="Name" value={user?.name} disabled />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <AvField name="dobk" label="DOB" value={moment(user?.dob).format("YYYY-MM-DD")} type="date" disabled />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <AvField name="mobilek" label="Mobile Number" value={user?.mobile} disabled />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <AvField name="emailk" label="Email Id" value={user?.email} disabled />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <AvField name="address" value={formData2?.address} label="Address" required />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <AvField name="pinCode" value={formData2?.pinCode} onChange={(e) => setPinCode(e.target.value)} label="Pin Code" required />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <AvField name="district" value={city ? city : formData2?.district} label="District" required />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <AvField name="state" value={state ? state : formData2?.state} label="State" required />
                                        </div>
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <AvField name="docType" value={formData2?.docType} onChange={(e) => setDocTp(e.target.value)} type="select" label="Select Doc Type" required >
                                                <option></option>
                                                <option value={"Adhar Card"}>Adhar Card</option>
                                                {/* <option value={"Pan Card"}>Pan Card</option> */}
                                                <option value={"Voter ID"}>Voter ID </option>
                                                <option value={"Driving License"}>Driving License</option>
                                            </AvField>
                                        </div>
                                    </div>
                                    {
                                        docTp ? <div class="col-lg-4">
                                            <div class="form-group">
                                                <AvField name="docNumber"
                                                    onChange={handleChange}
                                                    maxLength={docTp == 'Adhar Card' ? '14' : ''}
                                                    value={docTp == 'Adhar Card' ? adharValue : formData2?.docNumber} label="Number" required />
                                            </div>
                                            {/* <div className='doc-upload-box'>
                                            <label for="docNumber1">
                                                <input style={{ visibility: 'hidden' }} onChange={(e) => setDocFile(e.target.files[0])} type='file' id='docNumber1' />
                                                <img src={require('../assets/img/upload.png')} />
                                            </label>
                                        </div> */}
                                        </div>
                                            :
                                            null
                                    }
                                    {
                                        docTp ? <div class="col-lg-4">
                                            <div class="form-group">
                                                <AvField name="docNumber1" type="file" accept=".jpg,.jpeg,.png" onChange={(e) => setDocFile(e.target.files[0])} label="Doc Image" />
                                            </div>

                                        </div>
                                            :
                                            null
                                    }

                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <AvField name="panNumber"
                                                onChange={(e) => setPanNumber(e.target.value.toUpperCase())}
                                                validate={{
                                                    pattern: { value: /^[A-Z]{5}[0-9]{4}[A-Z]$/, errorMessage: 'Invalid PAN card number format' },
                                                }}
                                                value={panNumber ? panNumber : formData2?.panNumber} label="Pan Card Number" required />
                                        </div>
                                        {/* <div className='doc-upload-box'>
                                        <label for="panNumber1">
                                            <input style={{ visibility: 'hidden' }} type='file' onChange={(e) => setPanFile(e.target.files[0])} id='panNumber1' />
                                            <img src={require('../assets/img/upload.png')} />
                                        </label>
                                    </div> */}
                                    </div>
                                    <div class="col-lg-4 doc-file-box">
                                        <div class="form-group">
                                            <AvField name="panNumber1" type="file" accept=".jpg,.jpeg,.png" onChange={(e) => setPanFile(e.target.files[0])} label="Pan Card Image" />
                                        </div>
                                        {/* <div className='doc-upload-box'>
                                        <label for="panNumber1">
                                            <input style={{ visibility: 'hidden' }} type='file' onChange={(e) => setPanFile(e.target.files[0])} id='panNumber1' />
                                            <img src={require('../assets/img/upload.png')} />
                                        </label>
                                    </div> */}
                                    </div>


                                </div>

                                <div class="row">
                                    <div class="col-lg-12 button_box_col">
                                        <button type="button" className='gary-bg' onClick={() => setStep(2)}>
                                            Previous
                                        </button>
                                        <button type="submit" className='mx-3'>
                                            Next
                                        </button>
                                    </div>
                                </div>

                            </AvForm>
                        </div>
                        :
                        null
                }
                {
                    step == 4 ?

                        <div

                            class="contact-form-style-one" style={{ boxShadow: 'none' }}>


                            <AvForm onValidSubmit={handleValidSubmitBank}
                                class="contact-form contact-form">
                                <h3 className='title mb-5'>Bank Details</h3>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <AvField name="acHolder" value={formData3?.acHolder} label="Account Holder Name" required />
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <AvField name="acNumber" value={formData3?.acNumber} label="Account Number" required />
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <AvField name="reacNumber" value={formData3?.acNumber} label="Re-Account Number" required />
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <AvField name="ifscCode" value={formData3?.ifscCode} label="IFSC Code" required />
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <AvField name="bankName" value={formData3?.bankName} label="Bank Name" required />
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <AvField name="branch" value={formData3?.branch} label="Branch" required />
                                        </div>
                                    </div>


                                </div>
                                <h3 className='title mb-5'>UPI Details</h3>
                                <div className='row'>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <AvField name="upiId" value={formData3?.upiId} label="UPI ID" required />
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <AvField name="upiNumber" value={formData3?.upiNumber} label="UPI Number" required />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 button_box_col">
                                        <button type="button" className='gary-bg' onClick={() => setStep(3)}>
                                            Previous
                                        </button>
                                        <button type="submit" className='mx-3' >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </AvForm>
                        </div>
                        :
                        null
                }
                {
                    step == 5 ?
                        <div class="contact-form-style-one" style={{ boxShadow: 'none' }}>
                            <div class="row">
                                <div className='conditions_modal'>
                                    <h2 class="title mb-5">Terms & Conditions</h2>
                                    <div className='terms_content' dangerouslySetInnerHTML={{ __html: terms?.terms }} />

                                    <div className='aceept-box'>
                                        <input type='checkbox' onChange={(e) => setCheck(e.target.checked)} id='check' required />
                                        <label for="check">By clicking Agree and continue, you agree to the Apex Leap Terms & Conditions.</label>
                                    </div>
                                    {/* <button type="button" >
                                    <i class="fa fa-paper-plane"></i> Agree
                                </button> */}

                                </div>
                                <div class="row">
                                    <div class="col-lg-12 button_box_col">
                                        <button type="button" className='gary-bg' onClick={() => setStep(4)}>
                                            Previous
                                        </button>
                                        {
                                            check ?
                                                <button type="submit" onClick={() => { setStep(6); localStorage.setItem('step', 6) }} className='mx-3' >
                                                    Confirm
                                                </button>
                                                :
                                                null
                                        }
                                    </div>

                                </div>


                            </div>
                        </div>
                        :
                        null
                }

                {
                    step == 6 ?
                        <div class="contact-form-style-one" style={{ boxShadow: 'none' }}>

                            <div className='row mb-5'>
                                <div className='col-lg-5'>
                                    <div class="pricing-style-two pricing-header-box active" onClick={() => { setIsPay('IMPS'); setFile(null); setPayAmount(null); setIsAgree(false) }}>
                                        {
                                            isPay == 'IMPS' ? <span><i class="fa fa-check"></i></span> : null
                                        }

                                        <div class="pricing-header">
                                            <h4>{terms?.bank?.title}</h4>
                                            <ul>
                                                <li><strong>Company Name</strong> <p>{terms?.bank?.companyName}</p></li>
                                                <li><strong>Account Number</strong> <p>{terms?.bank?.acNumber}</p></li>
                                                <li><strong>IFSC Code</strong> <p>{terms?.bank?.ifscCode}</p></li>
                                                <li><strong>Bank</strong> <p>{terms?.bank?.bankName}</p></li>

                                            </ul>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div class="pricing-style-two  pricing-header-box  active" onClick={() => { setIsPay('Gpay'); setFile(null); setPayAmount(null); setIsAgree(false) }}>
                                        {
                                            isPay == 'Gpay' ? <span><i class="fa fa-check"></i></span> : null
                                        }
                                        <div class="pricing-header">
                                            <h4>{terms?.upi?.upiTitle}</h4>
                                            <ul>
                                                <li><strong>Gpay/Phone Pay ID</strong> <p>{terms?.upi?.upiId}</p></li>
                                                <li><strong>Gpay/phone Pay Name</strong> <p>{terms?.upi?.name}</p></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div class="pricing-style-two  pricing-header-box  active">
                                        <div className='qr-box-section'>
                                            <img src={require('../assets/img/QR.jpeg')} />
                                        </div>
                                    </div>
                                </div>

                                <h2 class="title mb-5 mt-5">Choose Plan</h2>

                                <div class="col-lg-12">
                                    <ul className='plan-name-list'>
                                        {
                                            plan?.map((item, index) => {
                                                return (
                                                    <li>
                                                        <div class="pricing-style-two pricing-header-box" onClick={() => setChoosePlan(item)}>
                                                            {
                                                                choosePlan?._id == item?._id ? <span><i class="fa fa-check"></i></span> : null
                                                            }

                                                            <div class="pricing-header">
                                                                <h5>{item?.title}</h5>
                                                                <p>{item?.tagLine}</p>

                                                            </div>
                                                            <div class="pricing-content">
                                                                <div class="price">
                                                                    <h2><sup>₹</sup>{item?.amount}/-<sup>+ {item?.gst}% GST</sup></h2>
                                                                    <p className='mb-0' style={{ fontSize: '12px' }}>A handling charge of ₹{item?.hCharge} will be applied.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>

                                </div>
                            </div>
                            <div>
                                {
                                    isPay == 'IMPS' ?
                                        <AvForm
                                            onValidSubmit={handleValidSubmitPayImps}
                                            class=" contact-form contact-form">
                                            <div class="row">
                                                <h2 class="title mb-5">{terms?.bank?.title}</h2>


                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <AvField name="total" label="Payable Amount" disabled value={amount} required />
                                                        {/* <p className='price_Text'>{amountText}</p> */}
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <AvField name="paid" label="Amount You Want to Pay (Min Amount ₹5000)" type="number" onChange={(e) => payAmountFun(e)} required />
                                                        {/* <p className='price_Text'>{amountText}</p> */}
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <AvField name="bal" label="Balance Amount" disabled value={payAmount ? (amount - payAmount) : 0} required />

                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <AvField name="acHolder" label="Your Name" required />
                                                    </div>
                                                </div>


                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <AvField name="acNumber" type="number" label="Your Account No." required />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <AvField name="txnId" label="Transaction Id" required />
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <AvField name="file" accept=".jpg,.jpeg,.png" onChange={(e) => setFile(e.target.files[0])} type="file" label="Receipt/Screenshot of Payment" required />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 ">
                                                    <div className='invitation_box'>
                                                        <div class="form-group">
                                                            <AvField name="inviteCode" label="Counselor Cust Code" />
                                                            <p>Enter the Invitation ID (Referrer Cust ID) provided by your referrer to join Apex Leap and unlock special rewards. Join our community and start earning today!</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group form-group-new">
                                                        <AvField type="checkbox" onChange={(e) => setIsAgree(e.target.checked)} name="avFieldCheckbox" required />
                                                        <label>By clicking Agree and continue, you agree to the Apex Leap Terms & Conditions.</label>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-lg-12 button_box_col">
                                                    <button type="button" className='gary-bg' onClick={() => { setStep(5); localStorage.setItem('step', 5); setCheck(false) }}>
                                                        Previous
                                                    </button>
                                                    {
                                                        isAgree ? <button type="submit" className='mx-3' >
                                                            Submit
                                                        </button> :
                                                            null
                                                    }
                                                </div>
                                            </div>

                                        </AvForm>
                                        :
                                        null

                                }
                                {
                                    isPay == 'Gpay' ?
                                        <AvForm
                                            onValidSubmit={handleValidSubmitPay}
                                            class=" contact-form contact-form">
                                            <div class="row">
                                                <h2 class="title mb-5">{terms?.upi?.upiTitle}</h2>

                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <AvField name="total" label="Payable Amount" disabled value={amount} required />
                                                        {/* <p className='price_Text'>{amountText}</p> */}
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <AvField name="paid" label="Amount You Want to Pay (Min Amount ₹5000)" type="number" onChange={(e) => payAmountFun(e)} required />
                                                        {/* <p className='price_Text'>{amountText}</p> */}
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <AvField name="bal" label="Balance Amount" disabled value={payAmount ? (amount - payAmount) : 0} required />

                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <AvField name="txnId" label="Transaction Id" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <AvField name="upiId" label="UPI Id" required />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <AvField name="upiNumber" label="UPI Number" required />
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <AvField name="file" onChange={(e) => setFile(e.target.files[0])} type="file" accept=".jpg,.jpeg,.png" label="Receipt/Screenshot of Payment" required />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 ">
                                                    <div className='invitation_box'>
                                                        <div class="form-group">
                                                            <AvField name="inviteCode" label="Counselor Cust Code" />
                                                            <p>Enter the Invitation ID (Referrer Cust ID) provided by your referrer to join Apex Leap and unlock special rewards. Join our community and start earning today!</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group form-group-new">
                                                        <AvField type="checkbox" onChange={(e) => setIsAgree(e.target.checked)} name="avFieldCheckbox" required />
                                                        <label>By clicking Agree and continue, you agree to the Apex Leap Terms & Conditions.</label>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-lg-12 button_box_col">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <button type="button" className='gary-bg' onClick={() => { setStep(5); localStorage.setItem('step', 5); setCheck(false) }}>
                                                                Previous
                                                            </button>
                                                            {
                                                                isAgree ? <button type="submit" className='mx-3' >
                                                                    Submit
                                                                </button> :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </AvForm>
                                        :
                                        null

                                }
                            </div>

                        </div>
                        :
                        null
                }
            </div >
        </>
    )
}
