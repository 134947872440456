import { AvField, AvForm } from 'availity-reactstrap-validation'
import React from 'react'
import { Link } from 'react-router-dom'

export default function UserProfile() {
    return (
        <div>
            <div className='inner-banner'>

            </div>
            <div class="contact-form-style-one" style={{ boxShadow: 'none' }}>


                <AvForm action="assets/mail/contact.php" method="POST" class="contact-form contact-form">
                    <h2 class="title mb-5">KYC</h2>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="name" label="Name" required />
                            </div>
                        </div>
                       
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="PinCode" label="Pin Code" required />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="District" label="District" required />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="State" label="State" required />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="dob" label="DOB" type="date" required />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="mobile" label="Mobile Number" required />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="email" label="Email Id" required />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="doc" label="Adhar/Voter ID/Driving License Number" required />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="pan" label="Pan Number" required />
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <AvField name="Address" label="Address" required />
                            </div>
                        </div>
                    </div>
                    <h3 className='title mb-5'>Bank Details</h3>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="ac" label="Account Number" required />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="reac" label="Re-Account Number" required />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="IFSCCode" label="IFSC Code" required />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="BankName" label="Bank Name" required />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="Branch" label="Branch" required />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="GpayPhoneID" label="Gpay/Phone Pay ID" required />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="GpayPhonePayNumber" label="Gpay/Phone Pay Number" required />
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <button type="submit" name="submit" id="submit">
                                <i class="fa fa-paper-plane"></i> Submit
                            </button>
                        </div>
                    </div>

                </AvForm>
            </div>
        </div>
    )
}
