import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Home from '../pages/Home';
import Header from '../component/Header';
import Footer from '../component/Footer';
import Registor from '../auth/Registor';
import Login from '../auth/Login';
import ForgotPassword from '../auth/ForgotPassword';
import UserProfile from '../auth/UserProfile';
import Plan from '../pages/Plan';
import MyProfile from '../auth/MyProfile';
import ForgetCust from '../auth/ForgetCust';
import Termsofuse from '../pages/Termsofuse';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import MyWallet from '../auth/MyWallet';
import Faq from '../pages/Faq';
import Terms from '../pages/Terms';
import Widraw from '../pages/Widraw';
import PaymentApp from "../pages/PaymentApp";

export default function Router() {

    const [isExpiry, setIsExpiry] = useState(false);

    const navigator = useNavigate()

    const user = useSelector((state) => state.user.data);
    const [isFooter, setIsFooter] = useState(true);

    useEffect(() => {
        if (user)
            if (moment(user?.expiry).isBefore(moment())) {
                localStorage.setItem('step', 6)
                navigator('/register')
            }
    }, [])




    return (
        // <BrowserRouter>
        <>

            <Routes>
                <Route index element={<Home />} />
                <Route path='/register' element={<Registor />} />
                <Route path='/login' element={<Login />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/forgot-cust' element={<ForgetCust />} />
                <Route path='/user-profile' element={<UserProfile />} />
                <Route path='/my-profile' element={<MyProfile />} />
                <Route path='/my-wallet' element={<MyWallet />} />
                <Route path='/plan' element={<Plan />} />
                <Route path='/terms-of-use' element={<Termsofuse />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/faq' element={<Faq />} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/withdraw/:amount/:wallet/:token' element={<Widraw setIsFooter={setIsFooter} />} />
                <Route path='/payment/:planId/:token' element={<PaymentApp setIsFooter={setIsFooter} />} />
            </Routes>
            {isFooter ?
                <Footer />
                : null}
        </>
    )
}