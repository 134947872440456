import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { post } from '../helpers/helper_api'

export default function ForgetCust() {
    const formRef = useRef(null)
    const handleValidSubmit = (e, v) => {
        let body = {
            ...v,
        }
        post('forgot_cust', body)
            .then((res) => {
                // console.log('res', res);
                if (res?.statusCode == 200) {
                    toast.success(res?.message)
                    formRef?.current?.reset()
                }
                if (res?.statusCode == 401) {
                    toast.success(res?.message)
                }
                if (res?.statusCode == 404) {
                    toast.success(res?.error)
                }
            }).catch(err => {
                console.log('errr', err)
            })
    }
    return (
        <div>
            <div className='inner-banner'>

            </div>
            <div class="contact-form-style-one" style={{ boxShadow: 'none' }}>

                <h2 class="title mb-5">Forget Cust Id</h2>
                <AvForm
                    ref={formRef}
                    onValidSubmit={handleValidSubmit}
                    class="contact-form contact-form">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <AvField name="email" label="Email" required />
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <button type="submit">
                                <i class="fa fa-paper-plane"></i> Submit
                            </button>
                        </div>
                    </div>

                    <div className='form-link mt-4'>

                        <p>Remember It ? <Link to={'/login'}> Sign In Here</Link></p>

                    </div>

                </AvForm>

            </div>
        </div>
    )
}
