
import breadcrumbReducer from './Breadcrumb/breadcrumbSlice';
import counterReducer from './user';
import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
  reducer: {
    breadcrumbReducer:breadcrumbReducer,
    user: counterReducer,
  },
})